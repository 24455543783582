import React, { useState } from "react"
import BgImg from "gatsby-background-image"
import styled from "styled-components"

import { H2White, colors, B1White, fontSizer } from "../../styles/helpers"

const Block = styled.div`
  position: relative;
  width: 100%;
  height: 100vw;
  border: 0.1rem solid ${colors.white};

  @media (min-width: 768px) {
    width: calc(33.33%);
    height: 40vw;
  }

  @media (min-width: 1025px) {
    height: 33.33vw;
  }

  .mainTitle {
    position: absolute;
    right: 0;
    bottom: 2.5rem;
    left: 0;
    width: 100%;
    padding: 2rem;
    transition: all 0.3s ease;
    opacity: 0;
    z-index: 5;

    @media (min-width: 1025px) {
      opacity: ${props => (props.isActive ? "0" : "1")};
      visibility: ${props => (props.isActive ? "hidden" : "visable")};
    }

    &__content {
      width: 100%;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      text-align: center;
      border-bottom: 0.1rem solid ${colors.white};

      h2 {
        ${H2White};
        margin: 0;
      }
    }

    &__link {
      width: 100%;
      text-align: center;

      p {
        ${B1White};
        ${fontSizer(1.2, 1.8, 76.8, 150, 1.6)}
        margin: 0;
        text-transform: uppercase;

        span {
          padding-right: 0.5rem;
        }
      }
    }
  }

  .hoverContent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem 4rem;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    z-index: 10;

    @media (min-width: 1025px) {
      opacity: ${props => (props.isActive ? "1" : "0")};
      visibility: ${props => (props.isActive ? "visable" : "hidden")};
    }

    &__container {
      width: 100%;
      max-width: 40rem;
      margin: auto;

      p {
        ${B1White};
        margin: 0;
      }
    }

    &__title {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 0.1rem solid ${colors.white};

      h2 {
        ${H2White};
        margin: 0;
        text-align: center;
      }
    }
  }

  .bgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const InfoBlocksItem = ({ block }) => {
  const [isActive, setIsActive] = useState(false)
  const handleHover = () => {
    setIsActive(true)
  }
  const handleHoverLeave = () => {
    setIsActive(false)
  }
  return (
    <Block
      isActive={isActive}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
    >
      <div className="mainTitle">
        <div className="mainTitle__content">
          <h2>{block.title}</h2>
        </div>
        <div className="mainTitle__link">
          <p>
            <span>&gt;</span>Learn More
          </p>
        </div>
      </div>
      <div className="hoverContent">
        <div className="hoverContent__container">
          <div className="hoverContent__title">
            <h2>{block.title}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: block.content }} />
        </div>
      </div>
      <div className="bgImage">
        <BgImg tag="div" fluid={block.image.localFile.childImageSharp.fluid} />
      </div>
    </Block>
  )
}

export default InfoBlocksItem
