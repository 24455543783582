import React from "react"
import styled from "styled-components"

import { H1Green, H3Black, standardWrapper } from "../../styles/helpers"

const SimpleContentSection = styled.section`
  padding: 2rem 0;

  @media (min-width: 768px) {
    padding: 4rem 0;
  }

  @media (min-width: 1025px) {
    padding: 8rem 0;
  }

  .wrapper {
    ${standardWrapper};
  }

  .simpleTitle {
    width: 100%;
    text-align: center;

    h2 {
      ${H1Green}
    }
  }

  .simpleContent {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    text-align: center;

    p {
      ${H3Black};
    }
  }
`

const SimpleContent = ({ simple }) => {
  return (
    <SimpleContentSection>
      <div className="wrapper">
        <div className="simpleTitle">
          <h2>{simple.acf._coo_simp_title}</h2>
        </div>
        <div
          className="simpleContent"
          dangerouslySetInnerHTML={{ __html: simple.acf._coo_simp_content }}
        />
      </div>
    </SimpleContentSection>
  )
}

export default SimpleContent
