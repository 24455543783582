import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import InfoBlockItem from "./InfoBlocksItem"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const InfoBlocksSection = styled.section`
  .wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
`
gsap.registerPlugin(ScrollTrigger)

const InfoBlocks = ({ info }) => {
  const blocks = info.acf._coo_infblo_info_blocks
  const wrapEle = useRef(null)

  useEffect(() => {
    const blocks = wrapEle.current.querySelectorAll(":scope > div")
    gsap.set(blocks, { autoAlpha: 0, y: 200 })
    gsap.to(blocks, {
      autoAlpha: 1,
      y: 0,
      duration: 0.75,
      stagger: {
        each: 0.25,
      },
      scrollTrigger: {
        trigger: blocks,
        markers: false,
        start: "top 90%",
        end: "bottom 0%",
        toggleActions: "play none none reverse",
      },
    })
  }, [])

  return (
    <InfoBlocksSection>
      <div ref={wrapEle} className="wrapper">
        {blocks.map((block, index) => {
          return <InfoBlockItem key={index} block={block} />
        })}
      </div>
    </InfoBlocksSection>
  )
}

export default InfoBlocks
