import React from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Block from "./Block"
gsap.registerPlugin(ScrollTrigger)

const BlocksSection = styled.section`
  padding: 5rem 0;

  @media (min-width: 768px) {
    padding: 7.5rem 0;
  }

  @media (min-width: 1024px) {
    padding: 10rem 0;
  }
`

const Blocks = props => {
  const blocks = props.blocks.acf._coo_contblos_content_blocks_2

  return (
    <BlocksSection>
      {blocks.map((block, index) => {
        const isReversed = index % 2 === 0
        return (
          <Block
            className="wrapper"
            block={block}
            isReversed={isReversed}
            key={index}
          />
        )
      })}
    </BlocksSection>
  )
}

export default Blocks
