import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/CommunityLife/Hero"
import Blocks from "../components/CommunityLife/Blocks"
import InfoBlocks from "../components/CommunityLife/InfoBlocks"
import BlocksTwo from "../components/CommunityLife/BlocksTwo"
import InfoBlocksTwo from "../components/CommunityLife/InfoBlocksTwo"
import SimpleContent from "../components/CommunityLife/SimpleContent"

const CommunityLife = props => {
  const {
    seoInfo,
    hero,
    simple,
    blocks,
    infoBlocks,
    blocksTwo,
    infoBlocksTwo,
  } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <Hero hero={hero} />
      <SimpleContent simple={simple} />
      <Blocks blocks={blocks} />
      <InfoBlocks info={infoBlocks} />
      <BlocksTwo blocks={blocksTwo} />
      <InfoBlocksTwo info={infoBlocksTwo} />
    </Layout>
  )
}

export const communityQuery = graphql`
  query communityLifePage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    hero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hohr_bg {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hohr_content
        _cco_hohr_button_required
        _coo_hohr_btn_text
        _coo_hohr_page_link_two
        _coo_hohr_bot_intro
      }
    }

    simple: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_simp_title
        _coo_simp_content
      }
    }

    blocks: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_contblos_content_blocks {
          title
          content
          link_required
          link
          button_text
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    infoBlocks: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_infblo_info_blocks {
          title
          content
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    blocksTwo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_contblos_content_blocks_2 {
          title
          content
          link_required
          link
          button_text
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    infoBlocksTwo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_infblo_info_blocks_2 {
          title
          content
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CommunityLife
