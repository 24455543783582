import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import BGImg from "gatsby-background-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {
  BigWrapper,
  colors,
  H2Green,
  B1Black,
  B1Green,
} from "../../styles/helpers"

import { wordSpiltter } from "../../utils/helperFunc"

const BlockStyle = styled.div`
  ${BigWrapper};
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  align-items: center;

  @media (min-width: 768px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  @media (min-width: 1024px) {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 5rem;
    z-index: 10;

    @media (min-width: 768px) {
      position: absolute;
      width: 50%;
      max-width: 63.4rem;
      height: 70vw;
      min-height: 50rem;
      max-height: 63.4rem;
      top: 50%;
      right: ${props => (props.isReversed ? "auto" : "45%")};
      left: ${props => (props.isReversed ? "45%" : "auto")};
      margin-bottom: 0;
      transform: translateY(-50%);
    }

    @media (min-width: 1024px) {
      width: 50%;
      max-width: 63.4rem;
      height: 100vw;
      max-height: 63.4rem;
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      background-color: ${colors.white};
      padding: 5rem 2rem;

      @media (min-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 5rem;
      }

      &--wrap {
      }
    }

    &__title {
      width: 100%;
      margin-bottom: 4.5rem;
      padding-bottom: 4.5rem;
      border-bottom: 0.1rem solid ${colors.colorSecondary};

      h2 {
        ${H2Green};
        margin: 0;
      }
    }

    &__para {
      width: 100%;
      p {
        ${B1Black};

        span {
          ${B1Black};
          @media (min-width: 768px) {
            display: block;
          }
        }
      }
    }

    &__link {
      width: 100%;
      a {
        ${B1Green};
        text-transform: uppercase;

        span {
          padding-right: 0.5rem;
        }
      }
    }

    &__background {
      position: absolute;
      top: 3rem;
      left: -3rem;
      width: 100%;
      height: 100%;
      background-color: ${colors.colorAlt};
      opacity: 0.44;
      z-index: -1;
    }
  }

  .image {
    position: relative;
    width: 100%;
    height: calc(100vw - 4rem);
    max-height: 40rem;
    margin-top: 5rem;

    @media (min-width: 768px) {
      width: calc(75%);
      height: calc(75vw - 2rem);
      max-height: 90rem;
      margin-top: 0;
    }

    @media (min-width: 1024px) {
      width: 75%;
      height: calc(75vw - 2rem);
      max-height: 90rem;
      margin-right: ${props => (props.isReversed ? "25%" : "auto")};
      margin-left: ${props => (props.isReversed ? "auto" : "25%")};
    }

    @media (min-width: 1600px) {
      max-height: 90rem;
    }

    &__background {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
    }
  }
`

const Block = ({ block, isReversed }) => {
  const splitSent = wordSpiltter(block.content, 25)

  const titleEle = useRef(null)
  const paraEle = useRef(null)
  const imgEle = useRef(null)

  const contentEle = useRef(null)

  useEffect(() => {
    const spans = paraEle.current.querySelectorAll("span")

    const startTheAnimations = () => {
      gsap.set(contentEle.current, { y: 50 })
      gsap.to(contentEle.current, {
        y: -50,
        duration: 1,
        scrollTrigger: {
          trigger: contentEle.current,
          markers: false,
          scrub: true,
          start: "top 100%",
          end: "top -120%",
        },
      })

      gsap.set(imgEle.current, { y: -50 })
      gsap.to(imgEle.current, {
        y: 50,
        duration: 1,
        scrollTrigger: {
          trigger: imgEle.current,
          markers: false,
          scrub: true,
          start: "top 100%",
          end: "top -120%",
        },
      })

      gsap.set([titleEle.current], {
        autoAlpha: 0,
        y: 25,
        rotation: 0,
        transformOrigin: "center left",
      })
      gsap.set(spans, {
        autoAlpha: 0,
        y: 25,
        rotation: 7,
        transformOrigin: "center left",
      })

      gsap.to([titleEle.current, spans], {
        autoAlpha: 1,
        y: 0,
        rotation: 0,
        stagger: {
          each: 0.1,
        },
        scrollTrigger: {
          trigger: titleEle.current,
          markers: false,
          start: "top 90%",
          end: "bottom 0%",
          toggleActions: "play none none reverse",
        },
      })
    }

    function reportWindowSize() {
      let mql = window.matchMedia("(min-width: 769px)")
      if (mql.matches) {
        startTheAnimations()
      }
    }

    reportWindowSize()
  }, [])

  return (
    <BlockStyle className="wrapper" isReversed={isReversed}>
      <div ref={contentEle} className="content">
        <div className="content__inner">
          <div className="content__inner--wrap">
            <div className="content__title">
              <h2 ref={titleEle}>{block.title}</h2>
            </div>
            <div className="content__para">
              <p
                ref={paraEle}
                dangerouslySetInnerHTML={{ __html: splitSent }}
              />
            </div>
          </div>
        </div>
        <div className="content__background" />
      </div>
      <div ref={imgEle} className="image">
        <BGImg
          tag="div"
          className="image__background"
          fluid={block.image.localFile.childImageSharp.fluid}
        />
      </div>
    </BlockStyle>
  )
}

export default Block
